<template>
  <CustomizationFunnel
    v-if="pickersResponse || !isPickersResponsePending"
    :grade="currentGrade"
    :pickers="pickersResponse?.pickers ?? []"
    :price="price"
    :price-without-subsidies
    :product="product"
    :product-tracking="productTracking"
    :selected-mobile-plan="selectedMobilePlan"
    :swap-listings="swapListings"
    :swap-offer="swapOffer"
    :swap-status="swapStatus"
  >
    <template #sticky-bar="{ title, currentStep, totalSteps }">
      <StickyBarTop
        :current-grade="currentGrade"
        :is-customization-funnel-enabled="true"
        :price="price"
        :product="product"
        :selected-mobile-plan="selectedMobilePlan"
        :swap-offer
        :swap-status="swapStatus"
        :title="title"
        :tracking="productTracking"
        @hide="handleHideTopBar"
        @show="handleShowTopBar"
      >
        <template #progress-bar>
          <ProgressBar :current-step="currentStep" :total-steps="totalSteps" />
        </template>
      </StickyBarTop>

      <StickyBarTopSmall
        :is-customization-funnel-enabled="true"
        :shipping="currentGrade.reassurance.shipping"
        :title="title"
        @hide="handleHideTopBar"
        @show="handleShowTopBar"
      >
        <template #progress-bar>
          <ProgressBar :current-step="currentStep" :total-steps="totalSteps" />
        </template>
      </StickyBarTopSmall>
    </template>
    <template #details>
      <Details
        :current-grade="currentGrade"
        :has-swap="false"
        :product="product"
        :product-category="productCategory"
        :should-display-vat="false"
        :show-seller-block="false"
        :swap-offer="swapOffer"
        :swap-status="swapStatus"
        :technical-specifications="technicalSpecifications"
      />
    </template>
  </CustomizationFunnel>
</template>

<script lang="ts" setup>
import { type Price } from '@backmarket/http-api'
import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { type GetBestOffersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/best-offers'
import { type GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { type GetTechnicalSpecificationsResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/technical-specifications'

import { type Estimation } from '~/scopes/buyback/api/adapters/getEstimationSwapPrice.adapter'

import { useProductTracking } from '../../composables/useProductTracking'
import CustomizationFunnel from '../CustomizationFunnel/CustomizationFunnel.vue'
import ProgressBar from '../CustomizationFunnel/components/ProgressBar/ProgressBar.vue'
import StickyBarTopSmall from '../CustomizationFunnel/components/StickyBarTopSmall/StickyBarTopSmall.vue'
import Details from '../Details/Details.vue'
import StickyBarTop from '../StickyBarTop/StickyBarTop.vue'

defineProps<{
  currentGrade: GetBestOffersResponse[number]
  isPickersResponsePending: boolean
  newBattery?: GetBestOffersResponse[number]['newBattery']
  pickersResponse: GetPickersResponse | null
  price: Price
  priceWithoutSubsidies?: Price
  product: GetProductResponse
  productCategory: string
  selectedMobilePlan?: MobilePlanOffer
  swapListings?: Array<number>
  swapOffer?: Estimation
  swapStatus?: string
  productTracking: ReturnType<typeof useProductTracking>
  technicalSpecifications: GetTechnicalSpecificationsResponse | null
}>()

const emit = defineEmits(['hide', 'show'])

function handleShowTopBar() {
  emit('show')
}
function handleHideTopBar() {
  emit('hide')
}
</script>
