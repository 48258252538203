<template>
  <RevButtonCard
    class="!bg-static-info-mid mb-32 flex min-h-[72px] w-full flex-row items-center gap-12 p-12 pr-16"
    @click="openDaModal"
  >
    <component
      :is="icon"
      v-if="icon"
      aria-hidden="true"
      class="m-4"
      :size="32"
    />
    <span class="body-2 line-clamp-3 flex-1 text-ellipsis text-left">
      {{ i18n(label) }}
    </span>
    <IconChevronRight />
  </RevButtonCard>
</template>

<script lang="ts" setup>
import { type Component } from 'vue'

import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonCard } from '@ds/components/ButtonCard'
import { openModal } from '@ds/components/ModalBase'
import { IconChevronRight } from '@ds/icons/IconChevronRight'

const props = defineProps<{
  modalName: string
  icon: Component
  label: I18nDefinition
}>()

const i18n = useI18n()

function openDaModal() {
  openModal(props.modalName)
}
</script>
