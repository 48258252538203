<template>
  <StepLayout :tracking="{ trackingId: 'visible', trackingModel: model }">
    <template #left>
      <div class="text-static-default-hi punchline flex flex-col items-end">
        <FormattedMessage :definition="translations.titleOnDesktop">
          <template #offerName>
            <span class="punchline italic text-[#1800FF]">
              {{ i18n(translations.offerName) }}
            </span>
          </template>
        </FormattedMessage>
      </div>
    </template>
    <template #right>
      <div class="flex">
        <div
          class="shadow-long rounded-lg flex w-[400px] flex-col gap-16 bg-[#1800FF] px-24 pt-16 md:px-32 md:pt-32"
        >
          <div class="text-static-light-hi punchline md:hidden">
            {{ i18n(translations.titleOnMobile) }}
          </div>

          <div class="text-static-light-hi">
            <FormattedMessage
              class="body-1-bold"
              :definition="translations.offerLabel"
            >
              <template #offerPriceWithoutDiscount>
                <span class="body-1-striked">
                  {{ i18n(translations.offerPriceWithoutDiscount) }}
                </span>
              </template>
            </FormattedMessage>
            <p class="caption">
              {{ i18n(translations.termsApply) }}
            </p>
          </div>

          <div>
            <RevButtonTiny
              class="mood-inverse"
              :tracking="{
                zone: 'visible',
                name: 'see_plan_details_visible',
              }"
              variant="primary"
              @click="openModal(MODAL_NAMES.VISIBLE_BY_VERIZON)"
            >
              {{ i18n(translations.buttonLabel) }}
            </RevButtonTiny>
          </div>

          <footer>
            <!-- Mobile -->
            <div class="flex items-center justify-end gap-20 md:hidden">
              <RevIllustration
                alt=""
                :height="95"
                src="/img/visible-by-verizon/character-cropped.png"
                :width="100"
              />
              <RevIllustration
                :alt="i18n(translations.logo)"
                class="md:hidden"
                :height="58"
                src="/img/visible-by-verizon/logo-two-lines.svg"
                :width="129"
              />
            </div>

            <!-- Desktop -->
            <div class="hidden items-center justify-between md:flex">
              <RevIllustration
                alt=""
                :height="94"
                src="/img/visible-by-verizon/character.png"
                :width="98"
              />
              <RevIllustration
                :alt="i18n(translations.logo)"
                :height="42"
                src="/img/visible-by-verizon/logo-with-backmarket.svg"
                :width="227"
              />
            </div>
          </footer>
        </div>
      </div>
    </template>
  </StepLayout>
</template>

<script setup lang="ts">
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { RevIllustration } from '@ds/components/Illustration'
import { openModal } from '@ds/components/ModalBase'

import { MODAL_NAMES } from '~/scopes/product/constants'

import StepLayout from '../StepLayout/StepLayout.vue'

import translations from './VisibleByVerizonOfferStep.translations'

const i18n = useI18n()

defineProps<{
  model?: string
}>()
</script>
